.topNav {
    background: none;
}

.topNav2nd {
    display: flex;
    justify-content: space-between;
    color: white;
    padding: 10px 0;
    width: 82%;
    margin: 0 auto;
}

.main {
    /* background: #012030; */
    background:none;
    padding: 0;
    margin: 0;
    box-shadow: 0 0px 20px rgba(255, 255, 255, 0.382);
}

.navx ul {
    list-style: none;
    display: flex;
    align-items: center;
    margin: auto 0;
    z-index: 999;
    padding: 20px 0;
}

.navx li {
    padding: 15px 0;
    font-size: 18px;
    color:#ffffff;
    position: relative;
    margin-right: 15px;
}

.navx a {
    color:#ffffff;
    transition: color 0.3s ease;
    text-decoration: none;
    font-family: monospace;
}

.navx ul a:hover {
    color: #ccfefe;
    border-bottom: 1px solid #ffffff;
    padding-bottom: 5px;
}

.navx {
    display: flex;
    justify-content: space-between;
    width: 82%;
    margin: auto;
    align-items: center;
    z-index: 999;
}

.serachCateSection {
    width: 100%;
    padding-bottom: 10px;
}

.serachCate2nd {
    display: grid;
    grid-template-columns: 100%;
    width: 82%;
    margin: 0 auto;
}



.searchBox {
    width: 100%;
    padding: 11px 0;
    padding-left: 10px;
    background: none;
    border: 1px solid#ffffff;
    outline: none;
    color: #f7f7f7;

}

.search {
    display: flex;
    align-items: center;
    position: relative;
}

.searchIcon {
    font-size: 22px;
    color: #fdfdfd;
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    border-right: 1px solid white;
    margin:  auto  ;
    align-items: center;
    padding: 12px 20px;
   
  
}

.navbarx {
    position: fixed;
    top: 0;
    left: 0;
    background: #00071d;
    padding: 0px 0;
    transition: background-color 0.3s ease;
    opacity: 0;
    visibility: hidden;
    z-index: 100;
}

.scrolled {
    background: #030017;
    box-shadow: 0 0px 20px rgba(255, 255, 255, 0.382);
    opacity: 1;
    visibility: visible;
    transition-delay: 0.4s;
    width: 100%;

}



.btnLink {
    cursor: pointer;
    z-index: 5;
}







.nav2nd {
    position: relative;
}



@media screen and (max-width :1200px) {
    .navx {
        display: grid;
        grid-template-columns: 50% 40%;
        justify-content: none;
        padding: 5px 20px;
    }

    .navx li {
        margin-right: 20px;
        font-size: 15px;
        padding: 0;
    }
    .categoryBtn {
        font-size: 18px;
    }
    
}
@media screen and (max-width : 1024px) {
    .serachCate2nd  {
        width: 90%;
    }
    .headerCate ul {
        margin-left: -30px;
    
    }
    .navx {
        width: 100%;
    }

        

}

@media screen and (max-width :1050px) {
    .navx {
        grid-template-columns: 30%  55%;
    }

    .navx li {
        margin-right: 25px;
    }
}


@media screen and (max-width :950px) {
    .navx {
        padding: 5px 8px;
        grid-template-columns: 30%  50%;
    }

    .navx li {
        margin-right: 13px;
        font-size: 14px;
    }

    .searchBox {
        width: 100%;
    }
    .categoryBtn {
        font-size: 16px;
    }
}
@media screen and (max-width :800px) {
    
   
    .categoryBtn {
        font-size: 15px;
    }
}
@media screen and (max-width :768px) {
    .topNav {
       display: none;;
    }
    
    .main {
        display: none;
    }
}