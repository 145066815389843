.webMain {
    color: white;
    display: flex;
    justify-content: space-evenly;
    padding: 150px 20px;
}

.detailsSec {
    margin-top: 20px;
    margin-left: 25px;
}

.detailsSec li {
    line-height: 50px;
}

@media only screen and (max-width:768px) {
    .detailsSec {
        margin-left: 5px;
    }
}