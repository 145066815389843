.sliderMain {
    width: 80%;
    margin: 0px auto;
    box-shadow: 0 0 15px 0px white;
    border-radius: 20px;
    border: 0.5px solid rgb(255, 255, 255);
}

.sliderMain img {
    border-radius: 20px;

}

.captionSec {
    background: rgba(0, 0, 0, 0.708);
}

.carousel-control-prev-icon{
    color: red !important;
    background-image: red !important;
}

@media only screen and (max-width:768px) {
    .sliderMain {
        width: 95%;
    }

    .captionSec h3 {
        font-size: 12px;
    }

    .captionSec p {
        font-size: 10px;
    }
}