.navMobileMain {
    display: none;
}



@media screen and (max-width : 768px) {
    .navMobileMain {
        display: block;
        margin: 0;
        padding: 0;

    }

    .navMobileBar {
        display: block;
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        background: #030017;
        z-index: 999;
        box-shadow: 0 0 5px 1px rgb(255, 255, 255);
        border-radius:50px 50px 0 0;
        /* margin: 0 5%; */
    }

    .navMobileBar ul {
        list-style: none;
        display: flex;
        justify-content: space-evenly;
        margin: 0;
        padding: 3px;
        align-items: center;
    }

    .navMobileBar li {
        padding: 0 4px;
        align-items: center;
        text-align: center;
    }

    .minTitle {
        font-size: 10px;
        padding: 0;
        margin: 0;
        color: white;
    }

    .navMobileBar a,
    .buttonCart {
        color: rgb(255, 255, 255);
        text-decoration: none;
        font-size: 20px;
    }

    .buttonCart {
       margin-right: -2px;
    }

    .cartmini {
        margin-right: 11px;
    }

    .navMobileBar a:hover {
        color: #000;
    }

    .navMobileTop {
        opacity: 0;
        visibility: visible;
        color: #ffffff;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background: #030017;
        box-shadow: 0 0 5px 1px rgb(255, 255, 255);
    }

    .navMobileTopMenu {
        padding: 10px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 999;
    }

    .navMobileTopScrolled {
        opacity: 1;
        visibility: visible;
        z-index: 999;

    }

    .navMobileTopNotScrolled {
        opacity: 1;
        visibility: visible;
        z-index: 999;
        display: block;
        width: 100%;
        background: #030017;

    }



    .active {
        background: rgb(255, 255, 255);
        border-radius: 12px 12px 0 0;
        width: 50px;
    }

    .active a {
        color: #030017 !important;
    }

    .active .minTitle {
        color: #030017 !important;
    }

    .search {
        width: 100%;
        display: flex;
        margin: -5px 0 5px;
        position: relative;
        padding: 0 10px;
    }

    .search input {
        width: 90%;
        outline: none;
        padding: 8px;
        border-radius: 10px;
        border: 0.5px solid #030017;
        color: #030017;
        margin: 0 auto 2px;
        font-size: 12px;
    }

    .searchIcon {
        position: absolute;
        right: 10%;
        top: 28%;
        font-size: 16px;
        color: #030017;
    }

}