.login-form {
    width: 400px;
    margin: 0 auto;
    padding: 30px;
    border-radius: 10px;
    background-color: #e6e6e671;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.392);
    position: relative;
    
  }
  
  .login-form h2, .login-form p  {
    text-align: center;
    font-weight: bold;
    color: #333;
  }
  .xxError {
    height: 90vh;
    
  }