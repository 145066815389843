.productSecMain{
    display: grid;
    grid-template-columns: 35% 35%;
    grid-gap: 10%;
    justify-content: space-evenly;
    color: white;
    margin: 10px;
}

.productPart{
    border: 1px dotted white;
    padding: 5px 10px;
    border-radius: 20px;
    width: 100%;
    margin-top: 20px;
}
.productPart img {
    width: 100%;
    height: 400px;
}


@media screen and (max-width: 1024px) {
    .productSecMain{
        grid-template-columns: 40% 40% ;
        grid-gap: 10%;
    }

}

@media screen and (max-width: 768px) {
    .productSecMain{
        grid-template-columns: 95% ;
        grid-gap: 10%;
        margin: 10px 0px;
    
    }
    .productPart{
        padding-left: 10px;
    }

}
