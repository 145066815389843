.headerMain{
    background: url(../Media/iii-soft.jpeg);
    background-size: cover; /* Ensures the image covers the entire area */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    background-attachment: fixed;
    width: auto;
    height: 80vh;
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    text-align: center;
}
.headerMain::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent black */
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5); /* Inner shadow */
}

.headerMain h2 {
    color: white;
    position: absolute;
    top: 30%;
   
}
.headerMain p {
    color: white;
    position: absolute;
    top: 40%;
    
}