.mobileCategory {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #030017;
    z-index: 1000;
}
.mobileCategory ul {
    list-style: none;
    padding: 0 5px;
    margin: 0;
   
}

.mobileCategory li {
    padding: 10px;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.468);
}

.mobileCategory  a {
  font-size: 16px;
  color:rgb(255, 255, 255);
  text-decoration: none;
}